<template>
  <div
    :style="
      'width: 100%;height: 100%;top: 0;left: 0;position: fixed;display:flex;background:#969aa585;z-index: 10000;text-align: center;justify-content: center;align-items: center;visibility:' +
      modal_carousel
    "
  >
    <input type="hidden" :class="visibleNoticias" />
    <div
      style="
        position: absolute;
        font-size: 40px;
        top: 0;
        width: 40px;
        right: 0;
        height: 40px;
        cursor: pointer;
        margin: 10px;
        margin-right: 20px;
      "
      @click="ocultarNoticia()"
    >
      X
    </div>
    <slider animation="fade" style="width: 80%; height: 90vh" :interval="4000">
      <slider-item v-for="(i, index) in itemsNoticia" :key="index">
        <img :src="i.imagen" class="img-fluid" />
        <p
          style="
            font-size: 1rem;
            text-align: center;
            bottom: 7%;
            position: absolute;
            width: 100%;
            color: black;
          "
        >
          {{ i.comentario }}
        </p>
      </slider-item>
    </slider>
  </div>
</template>


<script>
import { Slider, SliderItem } from "vue-easy-slider";
export default {
  name: "VerNoticiaUsuarios",
  components: {
    Slider,
    SliderItem,
  },
  data() {
    return {
      list: [
        { backgroundColor: "#3f51b5", width: "100%", height: "100%" },
        { backgroundColor: "#eee", width: "100%", height: "100%" },
        { backgroundColor: "#f44336", width: "100%", height: "100%" },
      ],
      modal_carousel: "hidden",
      itemsNoticia: [],
      mostrar: false,
    };
  },

  methods: {
    obtenerNoticia(tipo) {
      this.$http
        .post("/sms/noticias/usuarios", {
          tipo: tipo,
        })
        .then((response) => {
          this.itemsNoticia = response.data;
          if (this.itemsNoticia.length > 0) {
            this.modal_carousel = "visible";
          } else {
            this.ocultarNoticia();
          }
        })
        .catch(() => {});
    },
    ocultarNoticia() {
      this.modal_carousel = "hidden";
      this.$store.dispatch("auth/noticias_modal", "hidden");
    },
  },
  mounted() {
    setTimeout(() => {
      //this.obtenerNoticia("login");
    }, 200);
  },
  computed: {
    visibleNoticias() {
      let estado = this.$store.state.auth.noticias_modal;
      if (estado == "visible") {
        this.obtenerNoticia("login");
      }
      return estado;
    },
  },
};
</script>

<style>
.slider-btn-right {
  right: 0;
  background: linear-gradient(
    -90deg,
    rgb(0 0 0 / 0%),
    rgba(0, 0, 0, 0)
  ) !important;
}
.slider-btn-left {
  left: 0;
  background: linear-gradient(
    90deg,
    rgb(0 0 0 / 0%),
    rgb(0 0 0 / 0%)
  ) !important;
}
</style>